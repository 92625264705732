import { TCreateTransaction } from 'app/pages/User_MyPage/slice/transactionType';
import { TOptionsQuery } from 'types/common';
import { TTransaction } from 'types/transaction';
import { axiosClient } from '../axiosClient';
import queryString from 'query-string';

const transactionApi = {
  getTransactions: (params: TOptionsQuery<TTransaction>) => {
    const url = 'transactions';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
  sendPoint: (data: TCreateTransaction) => {
    const url = 'transactions';
    return axiosClient.post(url, data);
  },
};

export default transactionApi;
