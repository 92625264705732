import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import userApi from 'app/axios/api/userApi';
import { StatusCode } from 'app/axios/axiosClient';
import {
  getUserFromLocalStorage,
  removeRefreshToken,
  removeToken,
  removeUser,
  saveRefreshToken,
  saveToken,
  saveUser,
} from 'app/helpers/localStorage';
import { UserDetail } from 'types/user';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { AuthState, TResponseLogin } from './authTypes';

const initialState: AuthState = {
  authenticated: false,
  currentUser: {},
};

export const getCurrentUser = createAsyncThunk('auth/getCurrentUser', async (_, thunkAPI) => {
  return userApi
    .getCurrentUser()
    .then((res) => {
      thunkAPI.dispatch(setCurrentUser(res.data));
    })
    .catch(() => {
      thunkAPI.dispatch(logOut());
    });
});

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logIn(state, action: PayloadAction<TResponseLogin>) {
      saveToken(action.payload.tokens.access.token);
      saveRefreshToken(action.payload.tokens.refresh.token);
      saveUser(action.payload.user);
      state.authenticated = true;
      state.currentUser = action.payload.user;
    },
    logOut(state) {
      state.authenticated = false;
      state.currentUser = {};
      removeToken();
      removeRefreshToken();
      removeUser();
    },
    setAuth(state) {
      state.authenticated = true;
      state.currentUser = getUserFromLocalStorage();
    },
    setCurrentUser(state, action: PayloadAction<UserDetail>) {
      state.currentUser = action.payload;
      saveUser(action.payload);
    },
  },
});

export const { actions, reducer: authReducer } = slice;
export const { logIn, logOut, setAuth, setCurrentUser } = actions;
export default authReducer;
