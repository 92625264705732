import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Epath } from 'app/routes/routesConfig';
import s from './style.module.scss';
import {
  HomeOutlined,
  UserOutlined,
  ScanOutlined,
  SettingOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import c from 'clsx';

function Header() {
  const { pathname } = useLocation();

  return (
    <nav className={s.container}>
      <Link to={Epath.topScreen} className={[Epath.topScreen].includes(pathname) ? s.active : ''}>
        <HomeOutlined className={s.icon_header} />
        <p>ホーム</p>
      </Link>
      <Link
        to={Epath.productList}
        className={[Epath.productList].includes(pathname) ? s.active : ''}
      >
        <ShoppingOutlined className={s.icon_header} />
        <p>交換商品</p>
      </Link>
      <Link
        to={Epath.QRScanner}
        className={c(
          s.scanItem,
          [Epath.User_QRReading, Epath.MyQR_screen, Epath.QRScanner].includes(pathname)
            ? s.active
            : '',
        )}
      >
        <ScanOutlined className={s.icon_header} />
        <p>QR読込み</p>
      </Link>
      <Link
        to={Epath.User_MyPage}
        className={
          [Epath.User_MyPage, Epath.Point_sending_receiving].includes(pathname) ? s.active : ''
        }
      >
        <UserOutlined className={s.icon_header} />
        <p>マイページ</p>
      </Link>
      <Link
        to={Epath.Setting_screen}
        className={
          [Epath.Setting_screen, Epath.Change_email, Epath.Change_password].includes(pathname)
            ? s.active
            : ''
        }
      >
        <SettingOutlined className={s.icon_header} />
        <p>設定</p>
      </Link>
    </nav>
  );
}

export default Header;
