import { TScanQRCode } from 'app/pages/QRScanner/slice/qrPageTypes';
import { axiosClient } from '../axiosClient';

const qrCodeApi = {
  confirmQRProduct: (data: TScanQRCode) => {
    const url = 'qrCodes/confirm-qrcode';
    return axiosClient.post(url, data);
  },
  getQRProduct: (uuid: string) => {
    const url = `qrCodes/get-by-uuid/${uuid}`;
    return axiosClient.get(url);
  },
};

export default qrCodeApi;
