import { TOptionsQuery } from 'types/common';
import { TExchangeProduct } from 'types/product';
import { axiosClient } from '../axiosClient';
import queryString from 'query-string';

const productApi = {
  getExchangeProduct: (params: TOptionsQuery<TExchangeProduct>) => {
    const url = 'exchange-products';
    const query = queryString.stringify(params);
    return axiosClient.get(`${url}?${query}`);
  },
};

export default productApi;
