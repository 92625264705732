import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { TOptionsQuery, TResponsePagination } from 'types/common';
import { defaultPagination, DEFAULT_LIMIT, DEFAULT_PAGE } from 'app/helpers/common';
import transactionApi from 'app/axios/api/transactionApi';
import { TTransaction } from 'types/transaction';
import { TCreateTransaction, TransactionState } from './transactionType';
import { RootState } from 'types/RootState';
import { setErrorMessage, setSuccessMessage } from 'store/slice/messageSlice';
import { MESSAGE_TRANSACTION } from 'app/helpers/notifications/message';
import { getCurrentUser } from 'app/pages/AuthPage/slice/authSlice';

export const DEFAULT_SORT_TRANSACTIONS = 'createdAt:desc';
export const DEFAULT_LIMIT_TRANSACTIONS = 10;

const initialState: TransactionState = {
  params: {},
  loadingTransaction: false,
  transactions: [],
  pagination: defaultPagination,
};

export const getTransactions = createAsyncThunk(
  'transaction/getTransactions',
  async (params: TOptionsQuery<TTransaction>, thunkAPI) => {
    thunkAPI.dispatch(setParamsTransaction(params));

    const {
      sortBy = DEFAULT_SORT_TRANSACTIONS,
      limit = DEFAULT_LIMIT_TRANSACTIONS,
      page = DEFAULT_PAGE,
    } = params ? params : {};
    let newParams: TOptionsQuery<TTransaction> = Object.create(null);
    newParams.sortBy = sortBy;
    newParams.limit = limit;
    newParams.page = page;

    const response = await transactionApi.getTransactions({ ...params, ...newParams });
    return response.data as TResponsePagination<TTransaction[]>;
  },
);

export const sendPoint = createAsyncThunk(
  'transaction/sendPoint',
  async (data: TCreateTransaction, thunkAPI) => {
    thunkAPI.dispatch(setLoadingTransaction(true));
    const { params } = (thunkAPI.getState() as RootState).transaction;
    await transactionApi
      .sendPoint(data)
      .then(() => {
        thunkAPI.dispatch(getCurrentUser());
        thunkAPI.dispatch(getTransactions(params));
        thunkAPI.dispatch(setSuccessMessage(MESSAGE_TRANSACTION.SEND_POINT_SUCCESS));
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        thunkAPI.dispatch(setErrorMessage(message || MESSAGE_TRANSACTION.SEND_POINT_ERROR));
      });
    thunkAPI.dispatch(setLoadingTransaction(false));
  },
);

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setLoadingTransaction(state, action: PayloadAction<boolean>) {
      state.loadingTransaction = action.payload;
    },
    setParamsTransaction(state, action: PayloadAction<TOptionsQuery<TTransaction>>) {
      state.params = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTransactions.pending, (state: TransactionState) => {
      state.loadingTransaction = true;
    });
    builder.addCase(getTransactions.rejected, (state: TransactionState) => {
      state.loadingTransaction = false;
    });
    builder.addCase(
      getTransactions.fulfilled,
      (state: TransactionState, action: PayloadAction<TResponsePagination<TTransaction[]>>) => {
        const { results, ...pagination } = action.payload;
        state.loadingTransaction = false;
        state.transactions = action.payload.results;
        state.pagination = pagination;
      },
    );
  },
});

export const { actions, reducer: transactionReducer } = slice;
export const { setLoadingTransaction, setParamsTransaction } = actions;
export default transactionReducer;
