import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import qrCodeApi from 'app/axios/api/qrPageApi';
import { TQRCode } from 'types/qr';
import { QRPageState, TScanQRCode } from './qrPageTypes';
import { setErrorMessage, setSuccessMessage } from 'store/slice/messageSlice';
import { MESSAGE_QR } from 'app/helpers/notifications/message';

const initialState: QRPageState = {
  qrCode: {},
  confirmed: false,
  error: false,
  scaned: false,
  loadingQRPage: false,
};

export const confirmQRProduct = createAsyncThunk(
  'qrPage/confirmQRProduct',
  async (data: TScanQRCode, thunkAPI) => {
    thunkAPI.dispatch(setLoadingQRPage(true));
    await qrCodeApi
      .confirmQRProduct(data)
      .then((res) => {
        thunkAPI.dispatch(setConfirmedQRCode(res.data as TQRCode));
        thunkAPI.dispatch(setSuccessMessage(MESSAGE_QR.CONFIRM_QR_SUCCESS));
      })
      .catch((err) => {
        const message = err?.response?.data?.message;
        thunkAPI.dispatch(resetQRCode());
        thunkAPI.dispatch(setErrorMessage(message || MESSAGE_QR.CONFIRM_QR_ERROR));
      });
    thunkAPI.dispatch(setLoadingQRPage(false));
  },
);

export const scanQRProduct = createAsyncThunk(
  'qrPage/scanQRProduct',
  async (uuid: string, thunkAPI) => {
    thunkAPI.dispatch(setStatusScanQRCode(true));
    thunkAPI.dispatch(setLoadingQRPage(true));
    await qrCodeApi
      .getQRProduct(uuid)
      .then((res) => {
        thunkAPI.dispatch(setQRCode(res.data as TQRCode));
        thunkAPI.dispatch(setSuccessMessage(MESSAGE_QR.SCAN_QR_SUCCESS));
      })
      .catch(() => {
        thunkAPI.dispatch(setErrorQRCode(true));
        thunkAPI.dispatch(setErrorMessage(MESSAGE_QR.SCAN_QR_ERROR));
      });
    thunkAPI.dispatch(setLoadingQRPage(false));
  },
);

const slice = createSlice({
  name: 'qrPage',
  initialState,
  reducers: {
    setLoadingQRPage(state, action: PayloadAction<boolean>) {
      state.loadingQRPage = action.payload;
    },
    setQRCode(state, action: PayloadAction<TQRCode>) {
      state.error = false;
      state.qrCode = action.payload;
    },
    setConfirmedQRCode(state, action: PayloadAction<TQRCode>) {
      state.error = false;
      state.confirmed = true;
      state.scaned = false;
      state.qrCode = action.payload;
    },
    setStatusScanQRCode(state, action: PayloadAction<boolean>) {
      state.scaned = action.payload;
    },
    setErrorQRCode(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    resetQRCode(state) {
      state.confirmed = false;
      state.loadingQRPage = false;
      state.scaned = false;
      state.error = false;
      state.qrCode = {};
    },
  },
  extraReducers: (builder) => {},
});

export const { actions, reducer: qrPageReducer } = slice;
export const {
  setLoadingQRPage,
  setQRCode,
  resetQRCode,
  setConfirmedQRCode,
  setStatusScanQRCode,
  setErrorQRCode,
} = actions;
export default qrPageReducer;
