import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { TOptionsQuery, TResponsePagination } from 'types/common';
import { defaultPagination, DEFAULT_LIMIT, DEFAULT_PAGE } from 'app/helpers/common';
import productApi from 'app/axios/api/productApi';
import { TExchangeProduct } from 'types/product';
import { ProductState } from './productTypes';

export const DEFAULT_SORT_PRODUCTS = 'createdAt:desc';
export const DEFAULT_LIMIT_PRODUCTS = 12;

const initialState: ProductState = {
  params: {},
  loadingProduct: false,
  exchangeProducts: [],
  pagination: defaultPagination,
};

export const getExchangeProduct = createAsyncThunk(
  'product/getExchangeProduct',
  async (params: TOptionsQuery<TExchangeProduct>, thunkAPI) => {
    thunkAPI.dispatch(setParamsProduct(params));

    const {
      sortBy = DEFAULT_SORT_PRODUCTS,
      limit = DEFAULT_LIMIT_PRODUCTS,
      page = DEFAULT_PAGE,
    } = params ? params : {};
    let newParams: TOptionsQuery<TExchangeProduct> = Object.create(null);
    newParams.sortBy = sortBy;
    newParams.limit = limit;
    newParams.page = page;

    const response = await productApi.getExchangeProduct({ ...params, ...newParams });
    return response.data as TResponsePagination<TExchangeProduct[]>;
  },
);

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setLoadingProduct(state, action: PayloadAction<boolean>) {
      state.loadingProduct = action.payload;
    },
    setParamsProduct(state, action: PayloadAction<TOptionsQuery<TExchangeProduct>>) {
      state.params = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExchangeProduct.pending, (state: ProductState) => {
      state.loadingProduct = true;
    });
    builder.addCase(getExchangeProduct.rejected, (state: ProductState) => {
      state.loadingProduct = false;
    });
    builder.addCase(
      getExchangeProduct.fulfilled,
      (state: ProductState, action: PayloadAction<TResponsePagination<TExchangeProduct[]>>) => {
        const { results, ...pagination } = action.payload;
        state.loadingProduct = false;
        state.exchangeProducts = action.payload.results;
        state.pagination = pagination;
      },
    );
  },
});

export const { actions, reducer: productReducer } = slice;
export const { setLoadingProduct, setParamsProduct } = actions;
export default productReducer;
