import moment from 'moment';
import { IndexedObject, TPagination } from 'types/common';

// Common
export const isMobile = window.screen.width <= 1024;

export const DEFAULT_MAX_LENGTH_BIO = 210;

export const publicUrl = (uri: string) => {
  return `${process.env.PUBLIC_URL}${uri}`;
};

export const isEmptyObject = (obj: IndexedObject) => {
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }
  return JSON.stringify(obj) === JSON.stringify({});
};

export const isToday = (date: Date) => moment(date).isSame(moment(), 'day');

export const DEFAULT_LIMIT = 10;
export const DEFAULT_PAGE = 1;
export const DEFAULT_TOTAL_PAGES = 1;
export const DEFAULT_TOTAL_RESULTS = 0;

export const defaultPagination: TPagination = {
  limit: DEFAULT_LIMIT,
  page: DEFAULT_PAGE,
  totalPages: DEFAULT_TOTAL_PAGES,
  totalResults: DEFAULT_TOTAL_RESULTS,
};
