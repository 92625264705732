// Common
export const DEFAULT_TIP_SPIN = '読み込み中';
export const MESSAGE_ERROR_DEFAULT = 'エラー！変更内容がまだ保存されていません。';
export const MESSAGE_SUCCESS_DEFAULT = '変更内容が保存されました。';
export const MESSAGE_RE_LOGIN = 'もう一度ログインしてください。';
export const MESSAGE_COPIED = 'コピーした';

// Auth
export const MESSAGE_LOGIN_SUCCESS = 'ようこそ！';
export const MESSAGE_LOGIN_ERROR = 'ログインに失敗しました。';
export const MESSAGE_LOGOUT_SUCCESS = 'ログアウトしました。';

export const MESSAGE_SIGNUP_ERROR = 'サインアップ失敗！';
export const MESSAGE_SIGNUP_SUCCESS = 'サインアップ成功！メールを確認してください。';

export const MESSAGE_FORGOT_PASSWORD = 'メールを確認して割引を受け取ってください。';
export const MESSAGE_FORGOT_PASSWORD_ERROR = 'パスワード忘れに失敗しました。';

export const MESSAGE_CHANGE_PASSWORD_ERROR = 'パスワード変更に失敗しました。';
export const MESSAGE_CHANGE_PASSWORD_SUCCESS = 'パスワード変更が完了しました。';

export const MESSAGE_CHANGE_EMAIL_ERROR = 'メールアドレス変更に失敗しました。';
export const MESSAGE_CHANGE_EMAIL_SUCCESS = 'メールアドレス変更が完了しました。';

// Transaction
export const MESSAGE_TRANSACTION = {
  SEND_POINT_SUCCESS: 'ポイントの送信に成功しました。',
  SEND_POINT_ERROR: 'ポイントの送信に失敗しました。',
};

// QRCode
export const MESSAGE_QR = {
  SCAN_QR_SUCCESS: 'QRのスキャンに成功しました。',
  SCAN_QR_ERROR: 'QRのスキャンに失敗しました。',
  CONFIRM_QR_SUCCESS: 'QRの確認に失敗しました。',
  CONFIRM_QR_ERROR: 'QRの確認に失敗しました。',
};
