import { Spin } from 'antd';
import React from 'react';
import s from './style.module.scss';

const SuspenseFallback = () => {
  return (
    <div className={s.container}>
      <Spin size="large" />
    </div>
  );
};

export default SuspenseFallback;
