import { TChangePassword } from 'app/pages/AuthPage/slice/authTypes';
import { axiosClient } from '../axiosClient';

const userApi = {
  getCurrentUser: () => {
    const url = 'users/me';
    return axiosClient.get(url);
  },
};

export default userApi;
