export const Epath = {
  // NotFoundPage
  notFoundPage: '/notfound',

  // LoginPage
  loginPage: '/login',

  // Registration screen
  Registration_screen: '/registration',

  // Forgot password screen
  Forgot_password: '/forgot_password',

  // Top Screen
  topScreen: '/',

  // productList
  productList: '/products',

  // User_My Page
  User_MyPage: '/qr_mypager',

  // Setting screen
  Point_sending_receiving: '/point_sending_receiving',

  // User_QR Screen
  MyQR_screen: '/myqr_screen',

  // QRScanner Screen
  QRScanner: '/qr_scanner',

  // User_QR Reading
  User_QRReading: '/qr_reading',

  // Setting screen
  Setting_screen: '/setting_screen',

  // Change email
  Change_email: '/change_email',

  // Change password
  Change_password: '/change_password',

  // Policy
  policy: '/policy',

  // termsOfService
  termsOfService: '/terms_of_service',

  // notifications
  notifications: '/notifications',
};
