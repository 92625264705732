/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';
import authReducer from 'app/pages/AuthPage/slice/authSlice';
import productReducer from 'app/pages/ProductListScreen/slice/productSlice';
import transactionReducer from 'app/pages/User_MyPage/slice/transactionSlice';
import qrPageReducer from 'app/pages/QRScanner/slice/qrPageSlice';

import { InjectedReducersType } from 'utils/types/injector-typings';
import messageReducer from './slice/messageSlice';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export const rootReducers = {
  auth: authReducer,
  message: messageReducer,
  transaction: transactionReducer,
  qrPage: qrPageReducer,
  product: productReducer,
};

export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return (state: any) => state;
  } else {
    return combineReducers({
      ...injectedReducers,
    });
  }
}
