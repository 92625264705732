import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  getLanguage,
  getRefreshTokenFromLocalStorage,
  getTokenFromLocalStorage,
  getUserFromLocalStorage,
  saveLanguage,
} from './helpers/localStorage';
import RenderRoutes, { routes } from './routes/routes';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import './styles/variables.scss';
import './styles/base.scss';
import './styles/elements.scss';
import './styles/typography.scss';
import './styles/dependencies/index.scss';
import 'swiper/css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import './styles/customAntd.scss';
import { RootState } from 'types/RootState';
import { isEmptyObject } from './helpers/common';
import { getCurrentUser, setAuth } from './pages/AuthPage/slice/authSlice';
import MessageNotification from './components/Common/MessageNotification/MessageNotification';

// Set locate to moment lib
const language = getLanguage();
saveLanguage(language);
moment.locale(language);

const ConfigProviderCustom = ConfigProvider as any;

export function App() {
  const dispatch = useDispatch();
  const { authenticated, currentUser } = useSelector((state: RootState) => state.auth);

  const localUser = getUserFromLocalStorage();
  const localToken = getTokenFromLocalStorage();
  const localRefreshToken = getRefreshTokenFromLocalStorage();
  const checkAuthLocal = !!(
    localToken &&
    localRefreshToken &&
    localUser &&
    !isEmptyObject(localUser)
  );

  useEffect(() => {
    if (checkAuthLocal) {
      dispatch(setAuth());
    }
  }, [checkAuthLocal]);

  useEffect(() => {
    authenticated && checkAuthLocal && dispatch(getCurrentUser());
  }, [authenticated, checkAuthLocal]);

  const isAuthenticated = !!(authenticated && currentUser && Object.keys(currentUser).length);

  return (
    <ConfigProviderCustom
      theme={{
        token: {
          colorPrimary: '#25a28c',
        },
      }}
    >
      <Router basename="">
        <RenderRoutes routes={routes} isAuthenticated={isAuthenticated || checkAuthLocal} />
      </Router>
      <MessageNotification />
    </ConfigProviderCustom>
  );
}
