import React, { ReactNode } from 'react';
import Header from './Header/Header';
import s from './style.module.scss';

export type PropsLayout = {
  children: ReactNode;
};

function Layout({ children }: PropsLayout) {
  return (
    <div className={s.layout}>
      <div className={s.wrapper}>{children}</div>
      <div className={s.header}>
        <Header />
      </div>
    </div>
  );
}

export default Layout;
